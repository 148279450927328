<template>
  <div>
    <div class="home-banner">
      <img src="../../assets/logo.png" class="logo" />
    </div>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/home">首页</a></li>
          <li class="breadcrumb-item active" aria-current="page">个人中心</li>
        </ol>
      </nav>
      <div class="row">
        <div class="col-2">
          <div class="list-group">
            <a
              v-for="item in tabListMy"
              :key="item.code"
              @click="changeTabMy(item)"
              :class="[
                'list-group-item list-group-item-action',
                curCodeMy == item.code ? 'active' : '',
              ]"
              >{{ item.title }}
            </a>
          </div>
        </div>
        <div class="col-10">
          <div class="tab-content">
            <!-- 我的资料 -->
            <div class="tab-pane active" v-if="curCodeMy == 1">
              <h4 class="mb-4">我的资料</h4>
              <form class="row g-3" @submit.prevent="submitForm">
                <div class="col-md-6">
                  <label class="form-label"><span>*</span>姓名</label>
                  <input
                    type="span"
                    name="real_name"
                    v-model="userInfo.real_name"
                    class="form-control"
                    placeholder="请输入真实姓名"
                    required
                  />
                  <div class="valid-feedback">Looks good!</div>
                  <div class="invalid-feedback">
                    Please select a valid state.
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="form-label"><span>*</span>手机号</label>
                  <input
                    type="number"
                    name="mobile"
                    disabled
                    v-model="userInfo.mobile"
                    class="form-control"
                    placeholder="请输入手机号码（如绑定微信请输入绑定微信的手机号码）"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label"><span>*</span>身份</label>
                  <select class="form-select" name="position">
                    <option selected>请选择</option>
                    <option
                      :selected="item == userInfo.position"
                      v-for="item in positionList"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12">
                  <label class="form-label">所在单位</label>
                  <input
                    type="span"
                    name="company"
                    v-model="userInfo.company"
                    class="form-control"
                    placeholder="请规范填写全称"
                  />
                </div>
                <div class="col-md-4">
                  <label class="form-label"><span>*</span>所属区域</label>
                  <select
                    id="citySelect"
                    class="form-select"
                    name="city_code"
                    v-model="formData.city_code"
                    @change="changeCity($event)"
                  >
                    <option value="1" selected>请选择市</option>
                    <option
                      v-for="item in cityList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="form-label"><span>&nbsp;</span></label>
                  <select
                    id="districtSelect"
                    class="form-select"
                    name="district_code"
                    v-model="formData.district_code"
                    @change="changeDistrict($event)"
                  >
                    <option value="1" selected>请选择区/县</option>
                    <option
                      v-for="item in districtList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="form-label"><span>&nbsp;</span></label>
                  <select
                    id="streetSelect"
                    class="form-select"
                    name="region"
                    v-model="formData.region"
                  >
                    <option value="1" selected>请选择乡镇/单位</option>
                    <option
                      v-for="item in streetList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12">
                  <label class="form-label">
                    <span>*</span>行业（可多选）</label
                  >
                </div>
                <div class="col-12">
                  <div
                    class="form-check form-check-inline"
                    v-for="(item, index) in industryList"
                    :key="item"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :checked="userInfo.industry.includes(item)"
                      :id="'inlineCheckbox' + index"
                      name="industry"
                      :value="item"
                    />
                    <label
                      class="form-check-label"
                      :for="'inlineCheckbox' + index"
                    >
                      {{ item }}</label
                    >
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn bottom-btn">提交</button>
                </div>
              </form>
            </div>
            <!-- 我是志愿者 -->
            <div
              class="tab-pane active"
              v-if="curCodeMy == 2 && userInfo.is_volunteer == '1'"
            >
              <div class="my-vol">
                <h4 class="mb-4">
                  我是志愿者
                  <div class="my-vol-org" v-if="userInfo.volunteer_group">
                    <img
                      src="../../assets/volunteer/org-logo.png"
                      class="org-logo"
                    />{{ userInfo.volunteer_group.orga_name || "暂无组织" }}
                  </div>
                </h4>
                <div class="score-top">
                  <div class="score-detail">
                    <span class="score-label">公益时长</span>
                    <span class="txt-num">{{ userInfo.event_duration }}</span>
                  </div>
                  <div class="score-detail">
                    <span class="score-label">活动记录</span>
                    <span class="txt-num">{{ userInfo.event_count }}</span>
                  </div>
                  <div class="score-detail">
                    <a data-bs-toggle="modal" data-bs-target="#modal-volrule"
                      ><span class="iconfont icon-guize"></span
                      ><span class="score-label">爱心数 </span></a
                    >
                    <span class="txt-num">{{ userInfo.love_points }}</span>
                  </div>
                </div>
                <ul class="nav nav-tabs nav-blue">
                  <li class="nav-item">
                    <button class="nav-link active">我的活动记录</button>
                  </li>
                </ul>
                <!-- 参与活动列表 -->
                <div
                  class="vol-news-item"
                  v-for="item in myEventList"
                  :key="item.event_id"
                  @click="toEventDetail(item)"
                >
                  <div class="vol-news-pic">
                    <img :src="item.image" />
                  </div>
                  <span class="vol-news-title">{{ item.title }}</span>
                  <span class="vol-news-time">{{ item.created_at }}</span>
                </div>
                <div class="none-data" v-if="myEventList.length == 0">
                  <img src="../../assets/none.png" class="img-none" />
                </div>
              </div>
            </div>
            <!-- 组织负责人 -->
            <div
              class="tab-pane active"
              v-if="curCodeMy == 3 && userInfo.volunteer_group"
            >
              <div class="my-volorg">
                <h4 class="mb-4">
                  <div class="my-vol-org">
                    <img
                      :src="userInfo.volunteer_group.logo"
                      class="org-logo"
                    />
                    <h4>{{ userInfo.volunteer_group.orga_name }}</h4>
                  </div>
                  <router-link
                    v-if="userInfo && userInfo.is_volunteer_group == '1'"
                    class="btn btn-primary"
                    :to="{ path: '/volrequire', query: {} }"
                  >
                    <i class="iconfont icon-a-zu50"></i> 发需求
                  </router-link>
                </h4>
                <ul class="nav nav-tabs nav-blue">
                  <li class="nav-item">
                    <button
                      @click="changeMyOrgTab(1)"
                      :class="['nav-link', orgCode == 1 ? 'active' : '']"
                    >
                      我发布的需求
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      @click="changeMyOrgTab(2)"
                      :class="['nav-link', orgCode == 2 ? 'active' : '']"
                    >
                      组织成员
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      @click="changeMyOrgTab(3)"
                      :class="['nav-link', orgCode == 3 ? 'active' : '']"
                    >
                      活动报名成员
                    </button>
                  </li>
                </ul>
                <!-- 需求列表 -->
                <div class="eq-list ers-list" v-if="orgCode == 1">
                  <div
                    class="eq-item"
                    v-for="item in myPostEvents"
                    :key="item.event_id"
                    @click="toEventDetail(item)"
                  >
                    <div class="eq-title">
                      {{ item.title }}
                      <div class="eq-time">提交于 {{ item.created_at }}</div>
                    </div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div v-if="item.status == -1" class="eq-class tag-fail">
                        未通过
                      </div>
                      <div v-if="item.status == 0" class="eq-class">审核中</div>
                      <div v-if="item.status == 1" class="eq-class tag-success">
                        通过
                      </div>
                      <a hidden class="btn-edit"
                        ><i class="iconfont icon-bianji2"></i> 修改
                      </a>
                    </div>
                  </div>
                </div>
                <!-- 组织成员 -->
                <div class="mem-list" v-if="orgCode == 2">
                  <ul class="nav nav-tabs nav-blue">
                    <li class="nav-item">
                      <button
                        @click="changeOrgMemberCode('approved')"
                        :class="[
                          'nav-link',
                          orgMemberCode == 'approved' ? 'active' : '',
                        ]"
                      >
                        成员列表
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        @click="changeOrgMemberCode('apply')"
                        :class="[
                          'nav-link',
                          orgMemberCode == 'apply' ? 'active' : '',
                        ]"
                      >
                        组织申请列表
                      </button>
                    </li>
                  </ul>
                  <div class="mem-list" v-if="orgCode == 2">
                    <div class="mem-card-list">
                      <div
                        class="expert-card"
                        v-for="item in orgMemberList"
                        :key="item.id"
                      >
                        <div
                          class="expert-card-top"
                          @click="changeCurrentMember(item)"
                        >
                          <div class="expert-card-left">
                            <img class="avatar" :src="item.member.avatar" />
                            <div class="expert-intro">
                              <div class="expert-name">
                                {{ item.member.real_name }}
                              </div>
                            </div>
                          </div>
                          <i class="iconfont icon-youjiantou"></i>
                        </div>
                        <div
                          class="expert-card-bottom"
                          v-if="orgMemberCode == 'apply'"
                        >
                          <a
                            @click="toAgreeOrgMemberApply(item)"
                            class="btn btn-green"
                            >同意</a
                          >
                          <a
                            @click="toRejectOrgMemberApply(item)"
                            class="btn btn-red"
                            >拒绝</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 活动报名成员 -->
                <div class="event-mem-list" v-if="orgCode == 3">
                  <div class="eq-list ers-list">
                    <div
                      class="eq-item"
                      v-for="item in myOrgEventList"
                      :key="item.event_id"
                      @click="toEventDetail(item)"
                    >
                      <div class="eq-title">
                        {{ item.title }}
                        <button
                          class="btn btn-primary"
                          @click.stop="showEventMemberModal(item)"
                        >
                          去审核
                        </button>
                      </div>
                      <div class="eq-time">
                        <i class="iconfont icon-weibiaoti-3"></i>
                        {{ item.city + item.district + item.address }}
                      </div>
                      <div class="eq-time">
                        <i class="iconfont icon-shijian"></i>
                        {{ item.created_at }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 我的企业 -->
            <div class="tab-pane active" v-if="curCodeMy == 9">
              <!-- <h4 class="mb-4">我的企业</h4> -->
              <div class="mb-3">
                <label class="form-label">企业所属地市</label>
                <div class="form-area">
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    v-model="userInfo.company_city"
                    placeholder=""
                  />
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    v-model="userInfo.company_district"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">企业全称</label>
                <input
                  type="text"
                  disabled
                  class="form-control"
                  v-model="userInfo.company_name"
                  placeholder="请务必输入正确的企业全称（同营业执照）"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">您的职务</label>
                <input
                  type="text"
                  disabled
                  class="form-control"
                  v-model="userInfo.manager_position"
                  placeholder="请输入您的职务"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">您的真实姓名</label>
                <input
                  type="text"
                  class="form-control"
                  :value="userInfo.real_name"
                  disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">您的手机号码</label>
                <input
                  type="text"
                  class="form-control"
                  :value="userInfo.mobile"
                  disabled
                />
              </div>
            </div>
            <!-- 企业中心 -->
            <div class="tab-pane active" v-if="curCodeMy == 4">
              <div class="my-volorg">
                <h4 class="mb-4">
                  <div class="my-vol-org">
                    <i class="iconfont icon-wodeqiye"></i>
                    <h4>{{ userInfo.company_name }}</h4>
                  </div>
                  <button class="btn btn-primary" hidden>
                    <i class="iconfont icon-a-zu50"></i> 企业申报
                  </button>
                </h4>
                <ul class="nav nav-tabs nav-blue">
                  <li class="nav-item">
                    <button class="nav-link active">我的申报记录</button>
                  </li>
                </ul>
                <!-- 申报列表 -->
                <div class="eq-list ers-list">
                  <div
                    class="eq-item"
                    v-for="(item, index) in myRecordList.audit_history"
                    :key="item.apply_id"
                  >
                    <div class="eq-title">
                      {{
                        index == myRecordList.audit_history.length - 1
                          ? "企业申报"
                          : "企业复审"
                      }}
                      <div class="eq-time">提交于 {{ item.created_at }}</div>
                    </div>
                    <div v-if="item.status == 0" class="eq-class">审核中</div>
                    <div v-if="item.status == -1" class="eq-class tag-fail">
                      未通过
                    </div>
                    <div v-if="item.status == 1" class="eq-class tag-success">
                      通过
                    </div>
                    <div class="eq-des" v-if="item.status == -1">
                      {{ item.reason }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 我的专家留言 -->
            <div class="tab-pane active" v-if="curCodeMy == 5">
              <div class="my-vol">
                <h4 class="mb-4">
                  我的专家留言
                  <button class="btn btn-primary" hidden>
                    <i class="iconfont icon-tiwen1"></i> 留言给专家
                  </button>
                </h4>
                <div class="none-data" v-if="myQuestions.length == 0">
                  <img src="../../assets/none.png" class="img-none" />
                </div>
                <div class="eq-list">
                  <div
                    class="eq-item"
                    v-for="item in myQuestions"
                    :key="item.id"
                  >
                    <div class="eq-title">
                      <div class="eq-class">{{ item.industry }}</div>
                      {{ item.question }}
                    </div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div v-if="item.status == -1" class="eq-class tag-fail">
                        未通过
                      </div>
                      <div v-if="item.status == 0" class="eq-class">审核中</div>
                      <div v-if="item.status == 1" class="eq-class tag-success">
                        通过
                      </div>
                      <div class="d-flex">
                        <div class="eq-time">提交于 {{ item.created_at }}</div>
                        &nbsp;&nbsp;
                        <!-- <a v-if="item.status == -1"
                           class="btn-edit"><i class="iconfont icon-bianji2"></i> 修改
                        </a> -->
                      </div>
                    </div>
                    <i class="iconfont icon-canyuhuati"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- 我的收藏 -->
            <div class="tab-pane active" v-if="curCodeMy == 6">
              <div class="my-vol">
                <h4 class="mb-4">我的收藏</h4>
                <!-- 选项卡 -->
                <div class="nav-tabs-scroll">
                  <ul class="nav nav-tabs nav-blue">
                    <li
                      class="nav-item"
                      v-for="item in tabListFav"
                      :key="item.code"
                      @click="changeTabFav(item)"
                    >
                      <button
                        :class="[
                          'nav-link',
                          curCodeFav == item.code ? 'active' : '',
                        ]"
                      >
                        {{ item.title }}
                      </button>
                    </li>
                  </ul>
                </div>

                <div v-if="curCodeFav == 1">
                  <div class="safety-list">
                    <div
                      class="card"
                      v-for="item in favouriteList"
                      :key="item.identify"
                      @click="toDetail(curCodeFav, item)"
                    >
                      <div class="card-img-top">
                        <img :src="item.cover" />
                      </div>
                      <div class="card-body">
                        <div class="card-title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 2">
                  <div class="safety-list">
                    <div
                      class="card"
                      v-for="item in favouriteList"
                      :key="item.news_id"
                      @click="toDetail(curCodeFav, item)"
                    >
                      <div class="card-img-top">
                        <img :src="item.cover" />
                      </div>
                      <div class="card-body">
                        <div class="card-title">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 3">
                  <div class="safety-list">
                    <div
                      class="card"
                      v-for="item in favouriteList"
                      :key="item.video_id"
                      @click="toDetail(curCodeFav, item)"
                    >
                      <div class="card-img-top">
                        <img :src="item.cover" />
                      </div>
                      <div class="card-body">
                        <div class="card-title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 4">
                  <div class="safety-list">
                    <div
                      class="card"
                      v-for="item in favouriteList.list"
                      :key="item.paper_id"
                      @click="toDetail(item)"
                    >
                      <div class="card-img-top">
                        <img :src="item.cover" />
                      </div>
                      <div class="card-body">
                        <div class="card-title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 5">
                  <div class="safety-list">
                    <div
                      class="card"
                      v-for="item in favouriteList.list"
                      :key="item.paper_id"
                      @click="toDetail(item)"
                    >
                      <div class="card-img-top">
                        <img :src="item.cover" />
                      </div>
                      <div class="card-body">
                        <div class="card-title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 6 || curCodeFav == 7">
                  <div class="company-news-list">
                    <div
                      class="company-news-item"
                      v-for="item in favouriteList.list"
                      :key="item.paper_id"
                      @click="toDetail(curCodeFav, item.paper_id)"
                    >
                      <div class="company-news-title">
                        {{ item.title }}
                      </div>
                      <div class="company-news-time">{{ item.created_at }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 8 || curCodeFav == 9">
                  <div class="company-list">
                    <div
                      class="company-item"
                      v-for="item in favouriteList.list"
                      :key="item.company_id"
                      @click="toDetail(item)"
                    >
                      <div class="company-name">{{ item.name }}</div>
                      <!-- 如果是社会单位可能没有company-info -->
                      <div class="company-info" v-if="curCodeFav == 9">
                        <div class="company-nature">{{ item.tag }}</div>
                        <span
                          >首次取证时间 {{ item.first_approve_year }}年</span
                        >
                      </div>
                      <div class="name-box" v-else-if="curCodeFav == 8">
                        <span class="name-country"
                          >{{ item.named_at }}全国安全文化建设示范企业</span
                        >
                        <img
                          src="../../assets/company/name-shape.png"
                          class="name-shape"
                        />
                      </div>
                      <div class="company-intro">
                        {{ item.intro }}
                      </div>
                      <!-- 复审时间只有省级有 -->
                      <div v-if="curCodeFav == 9" class="company-recheck">
                        <div v-if="item.expired_at.length > 0">
                          <div class="status-expair">
                            {{ item.expired_at }}年度未按要求重新申报
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="item.next_check_year > 0">
                            下次复审时间：{{ item.next_check_year }}
                          </div>
                          <div
                            v-else-if="
                              item.recheck_status == '1' &&
                              item.recheck_year > 0
                            "
                          >
                            重新申报时间：{{ item.recheck_year }}
                          </div>
                          <div v-else>
                            <div class="status-fail">
                              {{ item.recheck_time }}年度复审未通过
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="company-shape"></div>
                      <div class="company-pattern">
                        <i class="iconfont icon-a-xingzhuang2"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 11">
                  <div class="safety-list">
                    <div
                      class="card"
                      v-for="item in favouriteList.list"
                      :key="item.paper_id"
                      @click="toDetail(item)"
                    >
                      <div class="card-img-top">
                        <img :src="item.cover" />
                      </div>
                      <div class="card-body">
                        <div class="card-title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 12">
                  <div class="vol-news-list">
                    <div
                      class="vol-news-item"
                      v-for="item in favouriteList.list"
                      :key="item.event_id"
                      @click="toEventDetail(item)"
                    >
                      <div class="vol-news-pic">
                        <img :src="item.image" />
                      </div>
                      <span class="vol-news-title">{{ item.title }}</span>
                      <span class="vol-news-time">{{ item.created_at }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="curCodeFav == 13">
                  <div class="vol-org-list">
                    <div
                      class="vol-org-item"
                      v-for="item in favouriteList.list"
                      :key="item.volunteer_group_id"
                      @click="toOrgDetail(item)"
                    >
                      <div class="vol-org-info">
                        <img :src="item.logo" class="org-logo" />
                        <div class="vol-org-intro">
                          <div class="vol-org-name">{{ item.orga_name }}</div>
                          <div class="vol-org-des">
                            {{ decodeContent(item.intro) }}
                          </div>
                        </div>
                      </div>
                      <div class="new-event">
                        <img
                          src="../../assets/volunteer/icon-new.svg"
                          class="icon-new"
                        />
                        <div class="new-event-title">标题</div>
                        <div class="new-event-des">
                          已报名 10人 <i class="iconfont icon-youjiantou"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="none-data" v-if="favouriteList.length == 0">
                  <img src="../../assets/none.png" class="img-none" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade modal-wait" id="ModalWait" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body span-center">敬请期待</div>
        </div>
      </div>
    </div>

    <!-- Modal 规则-->
    <div class="modal fade" id="modal-volrule" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">爱心榜规则</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">{{ decodeContent(volRule) }}</div>
        </div>
      </div>
    </div>
    <!-- Modal 审核报名成员 -->
    <div
      :class="['modal', showEventMemberListModal ? 'modal_apply_list' : '']"
      id="modal_apply_list"
      v-if="showEventMemberListModal"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">活动报名成员</h1>
            <button
              type="button"
              class="btn-close"
              @click="showEventMemberListModal = false"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mem-list">
              <ul class="nav nav-tabs nav-blue">
                <li class="nav-item">
                  <button
                    @click="changeOrgEventMemberCode('2')"
                    :class="[
                      'nav-link',
                      orgEventMemberCode == '2' ? 'active' : '',
                    ]"
                  >
                    成员列表
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    @click="changeOrgEventMemberCode('1')"
                    :class="[
                      'nav-link',
                      orgEventMemberCode == '1' ? 'active' : '',
                    ]"
                  >
                    申请列表
                  </button>
                </li>
              </ul>
              <div class="mem-card-list" v-if="orgCode == 3">
                <div
                  class="expert-card"
                  @click="changeCurrentMember(item)"
                  v-for="item in eventMemberList"
                  :key="item.id"
                >
                  <div
                    class="expert-card-top"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_mem_detail"
                  >
                    <div class="expert-card-left">
                      <img class="avatar" :src="item.member.avatar" />
                      <div class="expert-intro">
                        <div class="expert-name">
                          {{ item.member.real_name }}
                        </div>
                      </div>
                    </div>
                    <i class="iconfont icon-youjiantou"></i>
                  </div>
                  <div
                    class="expert-card-bottom"
                    v-if="orgEventMemberCode == '1'"
                  >
                    <a
                      @click="toAgreeEventMemberApply(item)"
                      class="btn btn-green"
                      >同意</a
                    >
                    <a
                      @click="toRejectEventMemberApply(item)"
                      class="btn btn-red"
                      >拒绝</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 志愿者详情-->
    <div
      :class="['modal', showMemberDetailModal ? 'modal_mem_detail' : '']"
      id="modal_mem_detail"
      v-if="showMemberDetailModal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">志愿者详情</h1>
            <button
              type="button"
              class="btn-close"
              @click="showMemberDetailModal = false"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">真实姓名</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="curMember.member.real_name"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">手机号码</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="curMember.member.mobile"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">身份</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="curMember.member.position"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">所在单位</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="curMember.member.company"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">所属区域</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="
                    curMember.member.city +
                    curMember.member.district +
                    curMember.member.street
                  "
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 col-form-label">服务列别</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="curMember.member.industry"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showMemberDetailModal || showEventMemberListModal"
      class="modal-backdrop show"
    ></div>
  </div>
</template>
<script>
import {
  getBaseData,
  areaList,
  updateUser,
  userDetail,
  myEvents,
  myApply,
  myQuestionList,
  myManagedEvent,
  safetyFavouriteList, //资讯收藏
  newsFavouriteList, //信息联动
  videoFavouriteList,
  questionFavouriteList,
  paperFavouriteList,
  compaynFavouriteList,
  eventFavouriteList,
  colunteerFavourtieList,
  myOrgMemberList,
  applyOrgMember,
  myOrgEventMemberList,
  applyOrgEventMember,
} from "@/network/API.js";
export default {
  data() {
    return {
      showEventMemberListModal: false,
      showMemberDetailModal: false,
      curMember: {},
      orgMemberCode: "approved",
      orgEventMemberCode: "1",
      orgCode: 1,
      userInfo: {},
      formData: {},
      positionList: [],
      industryList: [],
      cityList: [],
      districtList: [],
      streetList: [],
      curCodeMy: 1,
      curCodeVol: 1,
      curCodeFav: 1,
      myEventList: [], //我的活动记录
      myPostEvents: [], //我发布的需求
      myRecordList: [], //我的申报记录
      myQuestions: [], //我的留言
      tabListMy: [],
      tabListVol: [
        {
          code: 1,
          title: "电子证书",
        },
        {
          code: 2,
          title: "爱心榜规则",
        },
        {
          code: 3,
          title: "活动记录",
        },
      ],
      tabListFav: [
        {
          code: 1,
          title: "资讯动态",
        },
        {
          code: 2,
          title: "信息联动",
        },
        {
          code: 3,
          title: "短视频库",
        },
        {
          code: 4,
          title: "答疑解惑",
        },
        {
          code: 5,
          title: "企业风采",
        },
        {
          code: 6,
          title: "通知公告",
        },
        {
          code: 7,
          title: "政策规定",
        },
        {
          code: 8,
          title: "国家级企业名录",
        },
        {
          code: 9,
          title: "省级企业名录",
        },
        {
          code: 10,
          title: "社会单位名录",
        },
        {
          code: 11,
          title: "志愿资讯",
        },
        {
          code: 12,
          title: "志愿者活动",
        },
        {
          code: 13,
          title: "志愿者组织",
        },
      ],
      favouriteList: [],
      orgMemberList: [],
      eventMemberList: [],
      myOrgEventList: [],
      selectedMyEvent: {},
      volRule: "",
    };
  },
  async created() {
    this.userInfo = await userDetail();
    this.volRule = await getBaseData("event_top_rule");
    this.getPosition();
    this.userInfo.industry = this.userInfo.industry.split(",");
    this.formData = {
      ...this.userInfo,
    };

    this.tabListMy = [
      {
        code: 1,
        title: "我的资料",
      },
    ];
    if (this.userInfo.is_volunteer == "1") {
      this.tabListMy.push({
        code: 2,
        title: "我是志愿者",
      });
    }
    if (
      this.userInfo.volunteer_group &&
      this.userInfo.member_id == this.userInfo.volunteer_group.member_id
    ) {
      this.tabListMy.push({
        code: 3,
        title: "志愿者组织负责人",
      });
    }
    if (this.userInfo.is_company == 2) {
      this.tabListMy.push({
        code: 9,
        title: "我的企业",
      });
      this.tabListMy.push({
        code: 4,
        title: "企业中心",
      });
    }
    this.tabListMy.push({
      code: 5,
      title: "我的专家留言",
    });
    this.tabListMy.push({
      code: 6,
      title: "我的收藏",
    });
  },
  methods: {
    async toAgreeEventMemberApply(e) {
      await applyOrgEventMember({
        apply_id: e.apply_id,
        event_id: this.selectedMyEvent.event_id,
        type: "agree",
      });
      this.getOrgEventList();
    },
    async toRejectEventMemberApply(e) {
      await applyOrgEventMember({
        apply_id: e.apply_id,
        event_id: this.selectedMyEvent.event_id,
        type: "refuse",
      });
      this.getOrgEventList();
    },
    showEventMemberModal(event) {
      this.selectedMyEvent = event;
      this.showEventMemberListModal = true;
      this.getOrgEventList();
    },
    async toAgreeOrgMemberApply(e) {
      applyOrgMember({
        group_id: this.userInfo.volunteer_group.volunteer_group_id,
        apply_id: e.id,
        type: "agree",
      });
      this.getOrgMemberList();
    },
    async toRejectOrgMemberApply(e) {
      applyOrgMember({
        group_id: this.userInfo.volunteer_group.volunteer_group_id,
        apply_id: e.id,
        type: "refuse",
      });
      this.getOrgMemberList();
    },
    changeCurrentMember(e) {
      this.curMember = e;
      this.showMemberDetailModal = true;
    },
    changeOrgEventMemberCode(code) {
      if (code == this.orgEventMemberCode) return;
      this.orgEventMemberCode = code;
      this.getOrgEventList();
    },
    changeOrgMemberCode(code) {
      if (code == this.orgMemberCode) return;
      this.orgMemberCode = code;
      this.getOrgMemberList();
    },
    changeMyOrgTab(code) {
      if (code == this.orgCode) return;
      this.orgCode = code;
      if (code == 2) {
        this.orgMemberCode = "approved";
        this.getOrgMemberList();
      }
      if (code == 3) {
        this.orgEventMemberCode = "2";
        this.getMyMemberList();
      }
    },
    toEventDetail(item) {
      this.$router.push({
        name: "EventDetail",
        params: {
          id: item.event_id,
        },
      });
    },
    async getMyMemberList() {
      let data = await myManagedEvent({
        status: 1,
      });
      this.myOrgEventList = data;
    },
    async getOrgEventList() {
      let data = await myOrgEventMemberList({
        event_id: this.selectedMyEvent.event_id,
        apply_status: this.orgEventMemberCode,
      });
      this.eventMemberList = data.list;
    },
    async getOrgMemberList() {
      let data = await myOrgMemberList({
        group_id: this.userInfo.volunteer_group.volunteer_group_id,
        type: this.orgMemberCode,
      });
      this.orgMemberList = data.list;
    },
    async changeTabMy(t) {
      if (t.code == 2 && this.myEventList.length == 0) {
        this.myEventList = await myEvents();
      }
      if (t.code == 3 && this.myPostEvents.length == 0) {
        this.myPostEvents = await myManagedEvent();
      }
      if (t.code == 4 && this.myRecordList.length == 0) {
        this.myRecordList = await myApply();
      }
      if (t.code == 5) {
        let data = await myQuestionList();
        this.myQuestions = data.list;
      }
      if (t.code == 6) {
        this.getFavouriteList(1);
      }
      this.curCodeMy = t.code;
    },
    changeTabVol(t) {
      this.curCodeVol = t.code;
    },
    changeTabFav(t) {
      this.curCodeFav = t.code;
      this.getFavouriteList(t.code);
    },
    async getPosition() {
      let pData = await getBaseData("position");
      this.positionList = pData;
      let iData = await getBaseData("industry");
      this.industryList = iData;
      this.cityList = await areaList({
        code: "130000",
      });
      this.districtList = await areaList({
        code: this.userInfo.city_code,
      });
      this.streetList = await areaList({
        code: this.userInfo.district_code,
      });
    },
    async changeCity(event) {
      let newCity = event.target.value;
      this.formData.district_code = "1";
      this.formData.region = "1";
      let dData = await areaList({
        code: newCity,
      });
      this.districtList = dData;
    },
    async changeDistrict(event) {
      let newDistrict = event.target.value;
      this.formData.region = "1";
      let sData = await areaList({
        code: newDistrict,
      });
      this.streetList = sData;
    },
    async submitForm(event) {
      const data = new FormData(event.target);
      var params = {};
      var industry = [];
      for (let [a, b] of data) {
        params[a] = b;
      }
      params.industry = data.getAll("industry").join();
      params.city = this.cityList.filter(
        (item) => params.city_code == item.area_id
      )[0].area_name;
      params.district = this.districtList.filter(
        (item) => params.district_code == item.area_id
      )[0].area_name;
      params.street = this.streetList.filter(
        (item) => params.region == item.area_id
      )[0].area_name;
      params.mobile = this.userInfo.mobile;
      params.province = "河北省";
      params.province_code = "130000";
      console.log(params);

      let loader = this.$loading.show();

      await updateUser({
        ...params,
      });
      loader.hide();
      this.$toast("信息更新成功");
      this.userInfo = await userDetail();
      this.$store.dispatch("updateUser", this.userInfo);
    },
    toDetail() {},
    async getFavouriteList(type) {
      this.favouriteList = [];
      switch (type) {
        case 1:
          this.favouriteList = await safetyFavouriteList({
            code: "news",
          });
          break;
        case 2:
          this.favouriteList = await newsFavouriteList({});
          break;
        case 3:
          this.favouriteList = await videoFavouriteList({});
          break;
        case 4:
          this.favouriteList = await questionFavouriteList();
          break;
        case 5:
          this.favouriteList = await paperFavouriteList({
            code: "fengcai",
          });
          break;
        case 6:
          this.favouriteList = await paperFavouriteList({
            code: "notification",
          });
          break;
        case 7:
          this.favouriteList = await paperFavouriteList({
            code: "rules",
          });
          break;
        case 8:
          this.favouriteList = await compaynFavouriteList({
            company_type: 1,
            recheck_status: 1,
            approve_type: 1,
            recheck_year: "",
          });
          break;
        case 9:
          this.favouriteList = await compaynFavouriteList({
            company_type: 2,
          });
          break;
        case 10:
          this.$toast("敬请期待");
          break;
        case 11:
          this.favouriteList = await paperFavouriteList({
            code: "volunteer",
          });
          break;
        case 12:
          this.favouriteList = await eventFavouriteList({
            region_type: "1",
          });
          break;
        case 13:
          this.favouriteList = await colunteerFavourtieList();
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style>
.breadcrumb {
  padding: 0;
}

.home-banner {
  margin-bottom: 30px;
}

.list-group {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.list-group-item {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.tab-content {
  padding: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 600px;
}

.form-label span {
  color: red;
}

.bottom-btn {
  width: 260px;
  margin: 30px auto;
  position: relative;
  z-index: 4;

  border: none;
  color: #fff !important;
}

.my-vol h4,
.my-volorg h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.my-vol-org {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.my-vol-org .org-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  flex: 0 0 30px;
}

.my-vol-org .icon-wodeqiye {
  font-size: 30px;
  color: #3b75ea;
  margin-right: 10px;
}

.my-volorg .my-vol-org {
  font-size: 18px;
  display: flex;
  align-items: center;
}

/* .my-volorg .my-vol-org .org-logo {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  flex: 0 0 60px;
} */
.score-top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 10px;
}

.score-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.score-detail .score-label {
  font-size: 18px;
  line-height: 1.5em;
  color: #666666;
  margin-right: 10px;
}

.score-detail .icon-guize {
  font-size: 20px;
  text-decoration: none;
  margin-right: 5px;
  color: #3b75ea;
}

.score-detail a .score-label {
  text-decoration: none;
  color: #3b75ea;
}

.score-detail .txt-num {
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
}

/* .score-detail span.txt-small{
  font-size: 18px;
} */

.score-desc .desc {
  border-right: 1px solid #a2d1ec;
}

.score-desc .desc:last-child {
  border-right: none;
}

.desc i {
  margin-right: 12px;
}

.desc text {
  color: #123062;
  font-size: 30px;
  font-weight: 400;
}

.ers-list .eq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-tabs.nav-blue {
  justify-content: flex-start;
}

.btn-edit {
  text-decoration: none;
}

.eq-title .eq-class {
  background: #fff;
  border: 1px solid #3b75ea;
  color: #3b75ea;
}
/* 成员列表 */
.mem-list .nav-tabs {
  border-bottom: none;
}
.mem-list .nav-tabs.nav-blue .nav-link {
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
}
.mem-list .nav-tabs.nav-blue .nav-link.active {
  background: #3b75ea;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}
.mem-list .nav-tabs .nav-link {
  border-bottom: none;
}
.mem-list .mem-card-list {
  display: flex;
  flex-wrap: wrap;
}
.mem-list .expert-card {
  display: flex;
  flex-direction: column;
  margin: 1.5%;
  cursor: pointer;
}
.mem-list .expert-card .avatar {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.mem-list .expert-card-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mem-list .expert-name {
  font-size: 18px;
}
.mem-list .expert-card-top .icon-youjiantou {
  font-size: 24px;
  color: #3b75ea;
}

.mem-list .expert-card-left {
  display: flex;
  align-items: center;
}

.mem-list .expert-card-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.mem-list .btn {
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border-radius: 50px;
  margin: 0 5px;
  border: none !important;
}

.mem-list .btn-red {
  background: linear-gradient(108deg, #ffb990 0%, #ff3241 100%) !important;
}

.mem-list .btn-green {
  background: #00be6e !important;
}
.modal_mem_detail {
  display: block;
}
.modal_apply_list {
  display: block;
}
.form-area {
  display: flex;
  justify-content: space-around;
}
.form-area input {
  margin-right: 10px;
}
</style>
